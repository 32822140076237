import { createContext, FC, useContext } from 'react';

import { useAssetHooks } from '@/shared/api/assets/assets.hooks.ts';
import { CategoryService, categoryService } from '@/shared/services/category-ctree.service.ts';

import { WithChildren } from '@/shared/types/global/common.types.ts';

const CategoryContext = createContext<CategoryService>(categoryService);

export const CategoryProvider: FC<WithChildren> = ({ children }) => {
  const { data = [] } = useAssetHooks.getCategoryTrees();

  categoryService.initializeCategories(data);
  categoryService.initializeTransformCategories(data);

  return <CategoryContext.Provider value={categoryService}>{children}</CategoryContext.Provider>;
};

export const useCategoryContext = () => {
  const context = useContext(CategoryContext);

  if (!context) {
    throw new Error('useCategoryContext must be used within a CategoryProvider');
  }

  return context;
};
