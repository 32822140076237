import { lazy } from 'react';

export const LayoutRoutes = {
  Auth: lazy(() =>
    import('@/layout/Auth').then(({ AuthLayout }) => ({
      default: AuthLayout,
    })),
  ),

  Connection: lazy(() =>
    import('@/layout/Connection').then(({ ConnectionLayout }) => ({ default: ConnectionLayout })),
  ),

  Error: lazy(() =>
    import('@/layout/Error').then(({ ErrorLayout }) => ({
      default: ErrorLayout,
    })),
  ),

  Layout: lazy(() => import('@/layout/Layout').then(({ Layout }) => ({ default: Layout }))),

  Secure: lazy(() =>
    import('@/layout/Secure').then(({ SecureLayout }) => ({ default: SecureLayout })),
  ),

  Updater: lazy(() =>
    import('@/layout/Updater').then(({ UpdaterLayout }) => ({ default: UpdaterLayout })),
  ),
};
