import { FC } from 'react';

import { FullLoader } from '@/shared/components/interactions/Loader/FullLoader';
import { IndicatorLoader } from '@/shared/components/interactions/Loader/IndicatorLoader';
import { InlineLoader } from '@/shared/components/interactions/Loader/InlineLoader';
import { PingLoader } from '@/shared/components/interactions/Loader/PingLoader';
import { SectionLoader } from '@/shared/components/interactions/Loader/SectionLoader';
import { SuspenseLoader } from '@/shared/components/interactions/Loader/SuspenseLoader';

import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  appearance: 'custom' | 'full' | 'indicator' | 'inline' | 'ping' | 'section' | 'suspense';
  fullHeight?: boolean;
  indicatorColor?: string;
  loaderText?: JSX.Element | string;
  loaderTextClassName?: string;
  pingColor?: string;
  sectionLoaderClassName?: string;
};

export const Loader: FC<Props> = ({
  appearance,
  className,
  fullHeight,
  indicatorColor,
  loaderText,
  loaderTextClassName,
  pingColor,
  sectionLoaderClassName,
}) => {
  switch (appearance) {
    case 'full':
      return <FullLoader />;

    case 'indicator':
      return <IndicatorLoader className={className} indicatorColor={indicatorColor} />;

    case 'inline':
      return <InlineLoader />;

    case 'ping':
      return <PingLoader pingColor={pingColor} />;

    case 'section':
      return (
        <SectionLoader
          className={className}
          fullHeight={fullHeight}
          loaderText={loaderText}
          loaderTextClassName={loaderTextClassName}
          sectionLoaderClassName={sectionLoaderClassName}
        />
      );

    case 'suspense':
      return <SuspenseLoader />;
  }
};
