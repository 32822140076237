import { v4 } from 'uuid';

import { MISSING_DATA } from '@/shared/utils/constants.ts';

import { AssetCategory } from '@/shared/types/assets/assets.types.ts';
import {
  CategoryTypeEnum,
  FindCategoryByTypeProps,
  FindCategoryProps,
  GetCategoryProps,
  GetSubCategoryProps,
  RenderCategoryMethodsProps,
  TransformedCategory,
  TransformedCategoryPathItem,
} from '@/shared/types/global/category-ctree.service.types.ts';

/**
 * This service is responsible for handling the categories and subcategories of the assets.
 * It provides methods to retrieve the categories and subcategories based on the type.
 *
 * @methods with “...TransformedCategories...” is used to transform categories and subcategories.
 * For example, to add a form of equipment to the equipment table, etc.
 * This is necessary to format the string and remove unnecessary characters.
 * Note that the data structure here is slightly different from the input data.
 *
 * @methods with “...Category...” is used to get categories and subcategories.
 * This is similar to using a global state.
 * The structure is similar to the one that comes with the API
 * **/

export class CategoryService {
  private categories: AssetCategory[] = [];
  private transformedCategories: TransformedCategory[] = [];

  getAllCategories = (): AssetCategory[] => {
    try {
      return this.categories;
    } catch (error) {
      throw new Error(`Failed to retrieve categories. ${error}`);
    }
  };

  getAllTransformedCategories = (): TransformedCategory[] => {
    try {
      return this.transformedCategories;
    } catch (error) {
      throw new Error(`Failed to retrieve transformed categories. ${error}`);
    }
  };

  getCategoriesByType = (type: keyof typeof CategoryTypeEnum): AssetCategory[] => {
    try {
      return this.categories.filter(category =>
        category.categoryTreeName.includes(CategoryTypeEnum[type]),
      );
    } catch (error) {
      throw new Error(`Failed to retrieve categories for type: ${type}. ${error}`);
    }
  };

  getCategory = (treeIdentification: string): GetCategoryProps => {
    try {
      const category = this.findCategory(treeIdentification);
      if (!category) return null;

      const methods = this.renderCategoryMethods(category);

      return {
        ...category,
        ...methods,
      };
    } catch (error) {
      throw new Error(`Failed to retrieve category for ID: ${treeIdentification}. ${error}`);
    }
  };

  getCategoryByType = (type: keyof typeof CategoryTypeEnum): FindCategoryByTypeProps => {
    try {
      const category = this.findCategory(CategoryTypeEnum[type]);

      if (!category) return null;

      return category;
    } catch (error) {
      throw new Error(`Failed to retrieve category for type: ${type}. ${error}`);
    }
  };

  getSubCategory = (treeIdentification: string, pathResourceName: string): GetSubCategoryProps => {
    try {
      const currentCategory = this.findCategory(treeIdentification);
      if (!currentCategory) return null;

      return currentCategory.categoryPathList.find(
        path => path.categoryPathResName === pathResourceName,
      );
    } catch (error) {
      throw new Error(`Failed to retrieve subcategory for ID: ${treeIdentification}. ${error}`);
    }
  };

  getTransformedCategoriesByType = (type: keyof typeof CategoryTypeEnum): TransformedCategory[] => {
    try {
      return this.transformedCategories.filter(category =>
        category.categoryTreeName.includes(CategoryTypeEnum[type]),
      );
    } catch (error) {
      throw new Error(`Failed to retrieve transformed categories for type: ${type}. ${error}`);
    }
  };

  getTransformedCategory = (treeIdentification: string): TransformedCategory | undefined => {
    try {
      return this.transformedCategories.find(category => {
        return category.categoryPathItems.some(
          pathItem => pathItem.id === treeIdentification || pathItem.name === treeIdentification,
        );
      });
    } catch (error) {
      throw new Error(
        `Failed to retrieve transformed category for ID: ${treeIdentification}. ${error}`,
      );
    }
  };

  setTransformedCategories = (categories: TransformedCategory[]): void => {
    this.transformedCategories = categories;
  };

  private findCategory(treeIdentification: string): FindCategoryProps {
    return this.categories.find(
      category =>
        category.categoryTreeName === treeIdentification ||
        category.categoryTreeResName === treeIdentification,
    );
  }

  private renderCategoryMethods(category: AssetCategory): RenderCategoryMethodsProps {
    return {
      isEquipment: () => category.categoryTreeName.includes(CategoryTypeEnum.Equipment),
      isWorker: () => category.categoryTreeName.includes(CategoryTypeEnum.Worker),
    };
  }

  initializeCategories(categories: AssetCategory[]): void {
    this.categories = categories;
  }

  initializeTransformCategories(categories: AssetCategory[]): void {
    try {
      const result: TransformedCategory[] = [];

      categories.forEach(categoryTree => {
        const groupedPaths: Record<string, TransformedCategory> = {};

        categoryTree.categoryPathList.forEach(pathItem => {
          const [rootCategory, subCategory] = pathItem.categoryPath.split('/');

          const modifiedRootCategory = rootCategory.toLowerCase();

          if (!modifiedRootCategory) return;

          if (!groupedPaths[modifiedRootCategory]) {
            const modifiedPathResourceName = v4().replace(/-/g, '');

            groupedPaths[modifiedRootCategory] = {
              categoryPath: modifiedRootCategory ?? MISSING_DATA,
              categoryPathItems: [],
              categoryPathResName: `ctree-${modifiedPathResourceName}`,
              categoryTreeName: categoryTree.categoryTreeName,
              categoryTreeResName: categoryTree.categoryTreeResName,
              categoryTreeStatus: categoryTree.categoryTreeStatus,
              extended: categoryTree.extended,
            };
          }

          const transformedPathItem: TransformedCategoryPathItem = {
            categoryStatus: pathItem.categoryStatus,
            extended: pathItem.extended,
            id: pathItem.categoryPathResName,
            name: subCategory ?? MISSING_DATA,
          };

          groupedPaths[modifiedRootCategory].categoryPathItems.push(transformedPathItem);
        });

        result.push(...Object.values(groupedPaths));
      });

      this.transformedCategories = result;
    } catch (error) {
      throw new Error(`Failed to transform categories. ${error}`);
    }
  }
}

export const categoryService = new CategoryService();
