import { StateCreator } from 'zustand';

import { ActionSortCriteriaEnum } from '@/modules/Actions/types/actions.types.ts';
import { ActionsStoreSliceProps } from '@/shared/store/slices/actions/actions.types.ts';

export const createActionsSlice: StateCreator<ActionsStoreSliceProps> = (set, get) => ({
  addAlertData: newAlertData => {
    const alertsData = get().alertsData;
    const existing = alertsData.find(alert => alert.alertResName === newAlertData.alertResName);
    if (!existing) set({ alertsData: [...alertsData, newAlertData] });
  },
  alertsData: [],
  alertsFilters: {
    archive: {
      date: new Date(),
      searchKey: '',
      subCategories: [],
    },
    equipment: [],
    safety: [],
    sort: ActionSortCriteriaEnum.MOST_IMPORTANT,
    system: [],
    zone: [],
  },
  archivedAlertsData: [],
  isAlertsDataLoading: true,
  isArchiveAlertsDataLoading: true,
  isMetricsDataLoading: false,
  metricsData: [],
  moveAlertToArchive: (alertResourceName: string) => {
    const { alertsData, archivedAlertsData } = get();
    const alert = alertsData.find(alert => alert.alertResName === alertResourceName);

    if (alert) {
      const index = alertsData.indexOf(alert);
      const alertsBuffer = [...alertsData];
      const archivedAlertsBuffer = [...archivedAlertsData];

      archivedAlertsBuffer.push(...alertsBuffer.splice(index, 1));

      set({
        alertsData: alertsBuffer,
        archivedAlertsData: archivedAlertsBuffer,
      });
    }
  },
  setAlertsData: (newAlertsData, isLoading) => {
    set({
      alertsData: newAlertsData,
      isAlertsDataLoading: isLoading || false,
    });
  },
  setAlertsFilters: filters => {
    const existingFilters = get().alertsFilters;
    set({ alertsFilters: { ...existingFilters, ...filters } });
  },
  setArchivedAlertsData: (newAlertsData, append) => {
    if (append) {
      const archivedAlerts = get().archivedAlertsData;
      const buffer = [...archivedAlerts];

      for (const alert of newAlertsData) {
        if (!buffer.map(a => a.alertResName).includes(alert.alertResName)) {
          buffer.push(alert);
        }
      }

      set({ archivedAlertsData: buffer, isArchiveAlertsDataLoading: false });
    } else {
      set({ archivedAlertsData: newAlertsData, isArchiveAlertsDataLoading: false });
    }
  },
  setIsArchiveAlertsDataLoading: isLoading => {
    set({ isArchiveAlertsDataLoading: isLoading });
  },
  setMetricsData: (newMetricsData, isLoading) => {
    set({
      isMetricsDataLoading: isLoading || false,
      metricsData: newMetricsData,
    });
  },
  setWsAlertAvailable: available => {
    set({ wsAlertAvailable: available });
  },
  wsAlertAvailable: false,
});
