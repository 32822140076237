import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service';
import { LS_KEYS } from '@/shared/utils/constants';

import { SessionCredentialsProps } from '@/modules/Auth/types/aws-signature.types.ts';
import { UserProps } from '@/shared/types/users/user.types.ts';

export const getSessionCredentials = (): SessionCredentialsProps => {
  const localStorageUser = WebAPIStorageNamespace.LocalStorageService.getLocalItemParsed(
    LS_KEYS.USER_DATA,
  );

  const sessionStorageUser = WebAPIStorageNamespace.SessionService.getSessionItemParsed(
    LS_KEYS.USER_DATA,
  );

  const user = (localStorageUser ?? sessionStorageUser) as null | UserProps;

  return {
    accessKeyId: user?.accessKeyId ?? '',
    secretAccessKey: user?.secretAccessKey ?? '',
    sessionToken: user?.sessionToken ?? '',
  };
};
