import { FC, Suspense } from 'react';
import { Route, Routes } from 'react-router';

import { LayoutRoutes } from '@/layout/layout-routes.tsx';
import { ActionsRoutes } from '@/modules/Actions/actions-routes.tsx';
import { AuthRoutes } from '@/modules/Auth/auth-routes.tsx';
import { EquipmentRoutes } from '@/modules/Equipment/equipment-routes.tsx';
import { ErrorRoutes } from '@/modules/Error/error-routes.tsx';
import { HomeRoutes } from '@/modules/Home/home-routes.tsx';
import { LicencesRoutes } from '@/modules/Licences/licences-routes.tsx';
import { ReleasesRoutes } from '@/modules/Releases/releases-routes.tsx';
import { ReportsRoutes } from '@/modules/Reports/reports-routes.tsx';
import { ReservationsRoutes } from '@/modules/Reservations/reservations-routes.tsx';
import { SettingsRoutes } from '@/modules/Settings/settings-routes.tsx';
import { SubcontractorsRoutes } from '@/modules/Subcontractors/subcontracrors-routes.tsx';
import { Loader } from '@/shared/components/interactions/Loader';
import { ProtectedRoute } from '@/shared/components/navigation/ProtectedRoute';
import { useMixpanelConfigUpdate } from '@/shared/hooks/global/useMixpanelConfigUpdate.ts';
import { useAppFlagsChecks } from '@/shared/hooks/observer/useAppFlagsChecks.ts';
import { useRole } from '@/shared/hooks/roles/useRoleSecurity.tsx';
import { newRelicService } from '@/shared/services/newrelic.service';

import { RolesEnum, RoutesEnum } from '@/shared/types/global/enums.ts';

newRelicService.init();

export const AppRoutes: FC = () => {
  useMixpanelConfigUpdate();
  useAppFlagsChecks();

  const { renderItemVisibility } = useRole();

  const isKotoVisibility = renderItemVisibility([RolesEnum.KOTO]);
  const noSubcontractorVisibility = renderItemVisibility([RolesEnum.MANAGER, RolesEnum.ENGINEER]);
  const isDefaultVisibility = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.WORKER,
    RolesEnum.SUBCONTRACTOR,
    RolesEnum.ENGINEER,
  ]);
  const noWorkerVisibility = renderItemVisibility([
    RolesEnum.MANAGER,
    RolesEnum.SUBCONTRACTOR,
    RolesEnum.ENGINEER,
  ]);

  const isEnabled = true;

  return (
    <Suspense fallback={<Loader appearance="suspense" />}>
      <Routes>
        <Route element={<LayoutRoutes.Error />}>
          <Route element={<LayoutRoutes.Updater />}>
            {/*Public Routes*/}
            <Route element={<LayoutRoutes.Auth />}>
              <Route element={<AuthRoutes.Login />} path={RoutesEnum.LOGIN} />
              <Route element={<AuthRoutes.MFASetup />} path={RoutesEnum.MFA} />
              <Route element={<AuthRoutes.Terms />} path={RoutesEnum.TERMS} />
              <Route element={<AuthRoutes.SiteSelect />} path={RoutesEnum.SITE_SELECT} />
              <Route element={<AuthRoutes.RestorePassword />} path={RoutesEnum.RESTORE_PASSWORD} />
              <Route element={<AuthRoutes.MobileAuth />} path={RoutesEnum.MOBILE_AUTH} />
              <Route element={<AuthRoutes.TermsOfUse />} path={RoutesEnum.TERMS_OF_USE} />
            </Route>

            {/*Private Routes*/}
            <Route element={<LayoutRoutes.Secure />}>
              <Route element={<LayoutRoutes.Connection />}>
                <Route element={<LayoutRoutes.Layout />} path={RoutesEnum.HOME}>
                  {/*Home*/}
                  <Route element={<HomeRoutes.Home />} index />

                  {/*Reservations*/}
                  <Route
                    element={<ReservationsRoutes.Reservations />}
                    path={RoutesEnum.RESERVATIONS}
                  >
                    <Route element={<ReservationsRoutes.Schedule />} index />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={isDefaultVisibility}>
                          <ReservationsRoutes.History />
                        </ProtectedRoute>
                      }
                      path={RoutesEnum.RESERVATIONS_HISTORY}
                    />
                  </Route>

                  {/*Equipment*/}
                  <Route element={<EquipmentRoutes.Equipment />} path={RoutesEnum.EQUIPMENT}>
                    <Route element={<EquipmentRoutes.Equipments />} index />
                    <Route element={<EquipmentRoutes.EquipmentItem />} path=":equipment-id" />
                    <Route element={<EquipmentRoutes.Groups />} path="groups" />
                    <Route path="groups/:group-id" />
                  </Route>

                  {/*People*/}
                  <Route element={<SubcontractorsRoutes.Subcontractors />} path={RoutesEnum.PEOPLE}>
                    <Route
                      element={
                        <ProtectedRoute hasAccess={noWorkerVisibility || isKotoVisibility}>
                          <SubcontractorsRoutes.Firms />
                        </ProtectedRoute>
                      }
                      index
                    />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={noWorkerVisibility || isKotoVisibility}>
                          <SubcontractorsRoutes.SubcontractorItem />
                        </ProtectedRoute>
                      }
                      path=":subcontractor-id"
                    >
                      <Route element={<SubcontractorsRoutes.Details />} index />
                      <Route element={<SubcontractorsRoutes.WorkerProfile />} path=":worker-id" />
                      <Route element={<SubcontractorsRoutes.Reservations />} path="reservations" />
                    </Route>

                    <Route
                      element={
                        <ProtectedRoute hasAccess={noWorkerVisibility || isKotoVisibility}>
                          <SubcontractorsRoutes.Groups />
                        </ProtectedRoute>
                      }
                      path="groups"
                    />
                    <Route path="groups/:group-id" />
                  </Route>

                  {/*Reports*/}
                  <Route element={<ReportsRoutes.Reports />} path={RoutesEnum.REPORTS}>
                    <Route
                      element={
                        <ProtectedRoute hasAccess={noWorkerVisibility || isKotoVisibility}>
                          <ReportsRoutes.TimeZone />
                        </ProtectedRoute>
                      }
                      index
                    />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={isDefaultVisibility}>
                          <ReportsRoutes.Utilization />
                        </ProtectedRoute>
                      }
                      path="utilization"
                    />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={noSubcontractorVisibility}>
                          <ReportsRoutes.Trips />
                        </ProtectedRoute>
                      }
                      path="trips"
                    />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={!isEnabled}>
                          <ReportsRoutes.SitePresence />
                        </ProtectedRoute>
                      }
                      path="site-presence"
                    />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={isKotoVisibility}>
                          <ReportsRoutes.ZoneEntryExit />
                        </ProtectedRoute>
                      }
                      path="zone-entry-exit"
                    />
                  </Route>

                  {/*Actions*/}
                  <Route element={<ActionsRoutes.Actions />} path={RoutesEnum.ACTIONS} />

                  {/*Settings*/}
                  <Route element={<SettingsRoutes.Settings />} path={RoutesEnum.SETTINGS}>
                    <Route element={<SettingsRoutes.Profile />} index />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={noWorkerVisibility}>
                          <SettingsRoutes.SiteHours />
                        </ProtectedRoute>
                      }
                      path="site-hours"
                    />
                    <Route
                      element={
                        <ProtectedRoute
                          hasAccess={
                            !isDefaultVisibility && !isKotoVisibility && noWorkerVisibility
                          }
                        >
                          <SettingsRoutes.SiteMap />
                        </ProtectedRoute>
                      }
                      path="site-map"
                    />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={!isDefaultVisibility && !isKotoVisibility}>
                          <SettingsRoutes.Actions />
                        </ProtectedRoute>
                      }
                      path="actions"
                    />
                    <Route
                      element={
                        <ProtectedRoute hasAccess={!isDefaultVisibility && !isKotoVisibility}>
                          <SettingsRoutes.AutoApproval />
                        </ProtectedRoute>
                      }
                      path="auto-approval"
                    />
                  </Route>

                  {/*Releases*/}
                  <Route element={<ReleasesRoutes.Releases />} path={RoutesEnum.RELEASES} />

                  {/*Licences*/}
                  <Route element={<LicencesRoutes.Licences />} path={RoutesEnum.LICENSES} />
                </Route>
              </Route>
            </Route>
          </Route>
        </Route>

        <Route element={<ErrorRoutes.ErrorPage />} path="*" />
      </Routes>
    </Suspense>
  );
};
