import { lazy } from 'react';

export const SettingsRoutes = {
  Actions: lazy(() =>
    import('@/modules/Settings/pages/Actions').then(({ Actions }) => ({
      default: Actions,
    })),
  ),

  AutoApproval: lazy(() =>
    import('@/modules/Settings/pages/AutoApproval').then(({ AutoApproval }) => ({
      default: AutoApproval,
    })),
  ),

  Profile: lazy(() =>
    import('@/modules/Settings/pages/Profile').then(({ Profile }) => ({
      default: Profile,
    })),
  ),

  Settings: lazy(() =>
    import('@/modules/Settings/pages/Settings').then(({ Settings }) => ({
      default: Settings,
    })),
  ),

  SiteHours: lazy(() =>
    import('@/modules/Settings/pages/SiteHours').then(({ SiteHours }) => ({
      default: SiteHours,
    })),
  ),

  SiteMap: lazy(() =>
    import('@/modules/Settings/pages/SiteMap').then(({ SiteMap }) => ({
      default: SiteMap,
    })),
  ),

  System: lazy(() =>
    import('@/modules/Settings/pages/System').then(({ System }) => ({
      default: System,
    })),
  ),
};
