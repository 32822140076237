import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

export const PingLoader: FC<{
  pingColor?: string;
}> = ({ pingColor }) => (
  <span className="relative flex h-2 w-2 items-center justify-center">
    <span
      className={classnames(
        'absolute inline-flex h-full w-full animate-ping rounded-full opacity-75',
        pingColor ?? 'bg-white',
      )}
    />
    <span
      className={classnames(
        'relative inline-flex h-1.5 w-1.5 rounded-full bg-white',
        pingColor ?? 'bg-white',
      )}
    />
  </span>
);
