import { lazy } from 'react';

export const EquipmentRoutes = {
  Equipment: lazy(() =>
    import('@/modules/Equipment/pages/Equipment').then(({ Equipment }) => ({
      default: Equipment,
    })),
  ),

  EquipmentItem: lazy(() =>
    import('@/modules/Equipment/components/SingleItem').then(({ EquipmentItem }) => ({
      default: EquipmentItem,
    })),
  ),

  Equipments: lazy(() =>
    import('@/modules/Equipment/pages/Equipments').then(({ Equipments }) => ({
      default: Equipments,
    })),
  ),

  Groups: lazy(() =>
    import('@/modules/Equipment/pages/Groups').then(({ Groups }) => ({
      default: Groups,
    })),
  ),
};
