import { lazy } from 'react';

export const ReportsRoutes = {
  AuditLog: lazy(() =>
    import('@/modules/Reports/pages/AuditLog').then(({ AuditLog }) => ({
      default: AuditLog,
    })),
  ),

  PlanUpload: lazy(() =>
    import('@/modules/Reports/pages/PlanUpload').then(({ PlanUpload }) => ({
      default: PlanUpload,
    })),
  ),

  Reports: lazy(() =>
    import('@/modules/Reports/pages/Reports').then(({ Reports }) => ({
      default: Reports,
    })),
  ),

  SitePresence: lazy(() =>
    import('@/modules/Reports/pages/SitePresence').then(({ SitePresence }) => ({
      default: SitePresence,
    })),
  ),

  TagDiagnostics: lazy(() =>
    import('@/modules/Reports/pages/TagDiagnostics').then(({ TagDiagnostics }) => ({
      default: TagDiagnostics,
    })),
  ),

  TimeZone: lazy(() =>
    import('@/modules/Reports/pages/TimeZone').then(({ TimeZone }) => ({
      default: TimeZone,
    })),
  ),

  Trips: lazy(() =>
    import('@/modules/Reports/pages/Trips').then(({ Trips }) => ({
      default: Trips,
    })),
  ),

  Utilization: lazy(() =>
    import('@/modules/Reports/pages/Utilization').then(({ Utilization }) => ({
      default: Utilization,
    })),
  ),

  Workers: lazy(() =>
    import('@/modules/Reports/pages/Workers').then(({ Workers }) => ({
      default: Workers,
    })),
  ),

  ZoneEntryExit: lazy(() =>
    import('@/modules/Reports/pages/ZoneEntryExit').then(({ ZoneEntryExit }) => ({
      default: ZoneEntryExit,
    })),
  ),
};
