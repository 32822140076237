import { StateCreator } from 'zustand';

import { SiteResultProps } from '@/modules/Auth/api/sites/sites.types.ts';
import {
  CurrencyEnum,
  DisplayTimeZoneEnum,
  SystemViewEnum,
  TimeFormatEnum,
} from '@/modules/Settings/types/system.types.ts';
import { UiSliceProps } from '@/shared/store/slices/ui/ui.types.ts';
import { LocaleEnum } from '@/shared/types/global/i18n.types.ts';

export const createUISlice: StateCreator<UiSliceProps> = (set, get) => ({
  accountName: '',

  buttonsGroupView: 'time',

  clearTagsId: () => {
    set({ tagsId: [] });
  },

  currency: CurrencyEnum.USD,
  currentLanguage: LocaleEnum.EN,

  displayTimeZone: DisplayTimeZoneEnum.SITE,
  distance: 5,

  endTime: '',
  equipmentVisibility: true,

  floor: null,
  floorsList: [],

  isCanvasPopupsOpen: false,
  isCellCopyTipActive: true,

  isShowInactive: false,
  mapFilter: {
    actionEquipmentSubCategory: [],
    actionSafetySubCategory: [],
    actionSystemSubCategory: [],
    actionZoneSubCategory: [],
    assetItem: [],
    equipmentReservedBy: [],
    equipmentSubType: [],
    equipmentType: [],
    firm: [],
    group: [],
    resName: [],
    searchKey: '',
    tag: [],
    workerRole: [],
    zoneFloor: [],
    zoneItem: [],
    zoneType: [],
  },

  openedViewPanel: [],
  readerVisibility: false,

  setAccountName: (accountName: string) => {
    set({ accountName });
  },

  setButtonsGroupView: (buttonsGroupView: string) => {
    set({ buttonsGroupView });
  },

  setCurrency: (currency: CurrencyEnum) => {
    set({ currency });
  },

  setCurrentLanguage: (location: LocaleEnum) => {
    set({ currentLanguage: location });
  },
  setDisplayTimeZone: value => {
    set({ displayTimeZone: value });
  },

  setDistance: distance => {
    set({ distance });
  },
  setEndTime: value => {
    set({ endTime: value });
  },

  setEquipmentVisibility: value => {
    set({ equipmentVisibility: value });
  },
  setFloor: floor => {
    set({ floor });
  },

  setFloorsList: floorsList => {
    set({ floorsList });
  },
  setIsCanvasPopupsOpen: (value: boolean) => {
    set({ isCanvasPopupsOpen: value });
  },

  setIsCellCopyTipActive: value => {
    set({ isCellCopyTipActive: value });
  },
  setIsShowInactive: (isShowInactive: boolean) => {
    set({ isShowInactive });
  },

  setMapFilter: filter => {
    const mapFilter = get().mapFilter;
    // Also deselect any geofences if a map filters change
    set({
      mapFilter: { ...mapFilter, ...filter },
      visibleGeofenceIds: [],
    } as never);
  },
  setOpenedViewPanel: (openedViewPanel: string[]) => {
    set({ openedViewPanel });
  },

  setReaderVisibility: value => {
    set({ readerVisibility: value });
  },
  setSite: (site: string) => {
    set({ site });
  },

  setSiteCalendars: siteCalendars => {
    set({ siteCalendars });
  },
  setSitePolicies: sitePolicies => {
    set({ sitePolicies });
  },

  setSiteShifts: siteShifts => {
    set({ siteShifts });
  },
  setSitesList: (sitesList: SiteResultProps[]) => {
    set({ sitesList, sitesListLoading: false });
  },
  setSitesListLoading: loading => {
    set({ sitesListLoading: loading });
  },

  setSiteTimeZone: value => {
    set({ siteTimeZone: value });
  },
  setStartTime: value => {
    set({ startTime: value });
  },

  setSystemView: (view: SystemViewEnum) => {
    set({ systemView: view });
  },
  setTagId: (tagId: string) => {
    set(state =>
      state.tagsId.includes(tagId)
        ? { tagsId: state.tagsId.filter(id => id !== tagId) }
        : { tagsId: [...state.tagsId, tagId] },
    );
  },

  setTagIds: (tagsId: string[]) => {
    set({ tagsId });
  },
  setTimeFormat: value => {
    set({ timeFormat: value });
  },

  setUserSettings: settings => {
    set({ userSettings: settings });
  },
  setWorkerVisibility: value => {
    set({ workerVisibility: value });
  },
  site: '',
  siteCalendars: [],
  sitePolicies: [],
  siteShifts: [],

  sitesList: [],
  sitesListLoading: false,
  siteTimeZone: '',

  startTime: '',
  systemView: SystemViewEnum.METRIC,

  tagsId: [],
  timeFormat: TimeFormatEnum.H24,
  userSettings: [],
  workerVisibility: true,
});
