import { FC } from 'react';

import { SiteLogo } from '@/shared/components/ui/SiteLogo';

export const FullLoader: FC = () => {
  return (
    <div className="fixed inset-0 z-[9999] flex h-full w-full items-center justify-center bg-black/20 backdrop-blur-sm">
      <SiteLogo className="mx-auto h-[33px] w-[140px] animate-ping" />
    </div>
  );
};
