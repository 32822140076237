import { lazy } from 'react';

export const AuthRoutes = {
  Login: lazy(() => import('@/modules/Auth/pages/Login').then(({ Login }) => ({ default: Login }))),

  MFASetup: lazy(() =>
    import('@/modules/Auth/pages/MFASetup').then(({ MFASetup }) => ({ default: MFASetup })),
  ),

  MobileAuth: lazy(() =>
    import('@/modules/Auth/pages/MobileAuth').then(({ MobileAuth }) => ({ default: MobileAuth })),
  ),

  RestorePassword: lazy(() =>
    import('@/modules/Auth/pages/RestorePassword').then(({ RestorePassword }) => ({
      default: RestorePassword,
    })),
  ),

  SiteSelect: lazy(() =>
    import('@/modules/Auth/pages/Site').then(({ Site }) => ({ default: Site })),
  ),

  Terms: lazy(() =>
    import('@/modules/Auth/pages/Terms').then(({ Terms }) => ({
      default: Terms,
    })),
  ),

  TermsOfUse: lazy(() =>
    import('@/modules/Auth/pages/TermsOfUse').then(({ TermsOfUse }) => ({
      default: TermsOfUse,
    })),
  ),
};
