import { lazy } from 'react';

export const ReservationsRoutes = {
  History: lazy(() =>
    import('@/modules/Reservations/pages/History').then(({ History }) => ({ default: History })),
  ),

  Reservations: lazy(() =>
    import('@/modules/Reservations/pages/Reservations').then(({ Reservations }) => ({
      default: Reservations,
    })),
  ),

  Schedule: lazy(() =>
    import('@/modules/Reservations/pages/Schedule').then(({ Schedule }) => ({ default: Schedule })),
  ),
};
