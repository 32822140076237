import { FC } from 'react';

import { SUSPENSE_LOADER_BASE64_IMAGE } from '@/shared/components/interactions/Loader/SuspenseLoader/SuspenseLoader.constants.ts';

export const SuspenseLoader: FC = () => {
  return (
    <div
      className="fixed top-0 left-0 z-[9999] flex items-center justify-center w-screen h-screen bg-blue-600 bg-no-repeat bg-[center_48%]"
      style={{
        backgroundImage: `url(${SUSPENSE_LOADER_BASE64_IMAGE})`,
      }}
    >
      <div className="size-10 border-4 border-[#ddeaff] border-t-blue-600 rounded-full mt-[100px] animate-spin" />
    </div>
  );
};
