import { useCallback, useEffect, useState } from 'react';

import { WebAPIStorageNamespace } from '@/shared/services/api-storage.service.ts';
import { useGlobalStore } from '@/shared/store/store.ts';
import { LS_KEYS } from '@/shared/utils/constants.ts';

import { AuthStorage } from '@/modules/Auth/types/auth.types.ts';
import { UserProps } from '@/shared/types/users/user.types.ts';

export const useFetchUserData = () => {
  const getInitialUser = () => {
    const initialUserLocalStorageData = WebAPIStorageNamespace.LocalStorageService.getLocalItem(
      LS_KEYS.USER_DATA,
    );

    const initialUserSessionStorageData = WebAPIStorageNamespace.SessionService.getSessionItem(
      LS_KEYS.USER_DATA,
    );

    if (initialUserSessionStorageData) return JSON.parse(initialUserSessionStorageData);
    if (initialUserLocalStorageData) return JSON.parse(initialUserLocalStorageData);
    return null;
  };

  const [result, setResult] = useState<null | UserProps>(getInitialUser());
  const [authStorage, setAuthStorage] = useState<AuthStorage>(AuthStorage.LOCAL_STORAGE);

  const isAuthorized = useGlobalStore(state => state.isAuthorized);
  const setAuthorized = useGlobalStore(state => state.setAuthorized);
  const status = useGlobalStore(state => state.status);
  const watched = useGlobalStore(state => state.watched);

  const handleTransformUserProps = useCallback(
    (data: string, authStorage: AuthStorage) => {
      const parsedUserData: UserProps = JSON.parse(data);
      setAuthorized(true);
      setResult(parsedUserData);
      setAuthStorage(authStorage);
    },
    [setAuthorized],
  );

  useEffect(() => {
    const userLocalStorageData = WebAPIStorageNamespace.LocalStorageService.getLocalItem(
      LS_KEYS.USER_DATA,
    );

    const userSessionData = WebAPIStorageNamespace.SessionService.getSessionItem(LS_KEYS.USER_DATA);

    if (isAuthorized) {
      const authStorageField = userSessionData!
        ? AuthStorage.SESSION_STORAGE
        : AuthStorage.LOCAL_STORAGE;

      handleTransformUserProps(userSessionData! || userLocalStorageData!, authStorageField);
    } else if (userSessionData) {
      handleTransformUserProps(userSessionData, AuthStorage.SESSION_STORAGE);
    } else if (userLocalStorageData) {
      handleTransformUserProps(userLocalStorageData, AuthStorage.LOCAL_STORAGE);
    }
  }, [handleTransformUserProps, isAuthorized, status, watched]);

  return { authStorage, isAuthorized, user: result };
};
