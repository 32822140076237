import { Signer } from 'aws-amplify';
import axios, { AxiosInstance } from 'axios';

import { APIGatewayLinks } from '@/shared/api/api-gateway-links.ts';
import { getSessionCredentials } from '@/shared/utils/aws-signature';

import { MethodProps, SignRequestProps } from '@/modules/Auth/types/aws-signature.types.ts';

export const signRequest = async (
  _host: keyof typeof APIGatewayLinks,
  url: string,
  method: MethodProps,
  data?: unknown,
): Promise<SignRequestProps> => {
  const serviceInfo = {
    region: import.meta.env.VITE_AWS_REGION,
    service: import.meta.env.VITE_AWS_SERVICE_NAME,
  };

  const { accessKeyId, secretAccessKey, sessionToken } = getSessionCredentials();

  const params = {
    data: JSON.stringify(data),
    method,
    url: APIGatewayLinks[_host] + url,
  };

  const credentials = {
    access_key: accessKeyId,
    secret_key: secretAccessKey,
    session_token: sessionToken,
  };

  const signedRequest = Signer.sign(params, credentials, serviceInfo);

  delete signedRequest.headers['host'];

  return signedRequest;
};

export const generateQueryString = <T extends Record<string, unknown>>(params: T): string => {
  const queryParams = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (Array.isArray(value)) {
      value.forEach(item => {
        queryParams.append(key, String(item));
      });
    } else if (value !== undefined) {
      queryParams.append(key, String(value));
    }
  });

  return queryParams.toString();
};

export const baseQuery = (baseURL: keyof typeof APIGatewayLinks): AxiosInstance => {
  if (!baseURL) {
    throw new Error('Please define the right environment variable inside .env');
  }

  return axios.create({
    baseURL: APIGatewayLinks[baseURL],
  });
};
