import { lazy } from 'react';

export const SubcontractorsRoutes = {
  Actions: lazy(() =>
    import('@/modules/Settings/pages/Actions').then(({ Actions }) => ({
      default: Actions,
    })),
  ),

  Archive: lazy(() =>
    import('@/modules/Subcontractors/pages/Archive').then(({ Archive }) => ({
      default: Archive,
    })),
  ),

  Details: lazy(() =>
    import('@/modules/Subcontractors/pages/Details').then(({ Details }) => ({
      default: Details,
    })),
  ),

  Equipment: lazy(() =>
    import('@/modules/Equipment/pages/Equipment').then(({ Equipment }) => ({
      default: Equipment,
    })),
  ),

  Firms: lazy(() =>
    import('@/modules/Subcontractors/pages/Firms').then(({ Firms }) => ({
      default: Firms,
    })),
  ),

  Groups: lazy(() =>
    import('@/modules/Equipment/pages/Groups').then(({ Groups }) => ({
      default: Groups,
    })),
  ),

  Requests: lazy(() =>
    import('@/modules/Subcontractors/pages/Requests').then(({ Requests }) => ({
      default: Requests,
    })),
  ),

  Reservations: lazy(() =>
    import('@/modules/Subcontractors/pages/Reservations').then(({ Reservations }) => ({
      default: Reservations,
    })),
  ),

  SubcontractorItem: lazy(() =>
    import('@/modules/Subcontractors/pages/SubcontractorItem').then(({ SubcontractorItem }) => ({
      default: SubcontractorItem,
    })),
  ),

  Subcontractors: lazy(() =>
    import('@/modules/Subcontractors/pages/Subcontractors').then(({ Subcontractors }) => ({
      default: Subcontractors,
    })),
  ),

  Unresolved: lazy(() =>
    import('@/modules/Subcontractors/pages/Unresolved').then(({ Unresolved }) => ({
      default: Unresolved,
    })),
  ),

  WorkerProfile: lazy(() =>
    import('@/modules/Subcontractors/pages/WorkerProfile').then(({ WorkerProfile }) => ({
      default: WorkerProfile,
    })),
  ),
};
