import { useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';

import { useAlertsFeatureFlag } from '@/shared/feature-flags/useAlertsFeatureFlag.ts';
import { useReservationsControlFlag } from '@/shared/feature-flags/useReservationsControlFlag.ts';

import { RoutesEnum } from '@/shared/types/global/enums.ts';

export function useAppFlagsChecks() {
  const navigate = useNavigate();
  const location = useLocation();

  const { alertsImplementation } = useAlertsFeatureFlag();
  const { reservationControlFlag } = useReservationsControlFlag();

  useLayoutEffect(() => {
    const alertsRoutes = [RoutesEnum.ACTIONS];
    const reservationRoutes = [RoutesEnum.RESERVATIONS];

    const currentPath = location.pathname;

    if (alertsRoutes.some(route => currentPath.includes(route)) && !alertsImplementation.enabled)
      navigate(RoutesEnum.HOME);

    if (
      reservationRoutes.some(route => currentPath.includes(route)) &&
      !reservationControlFlag.enabled
    )
      navigate(RoutesEnum.HOME);
  }, [location.pathname, alertsImplementation.enabled, reservationControlFlag.enabled, navigate]);
}
