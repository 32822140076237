import { FC } from 'react';
import { Navigate } from 'react-router';

import { WithChildren } from '@/shared/types/global/common.types.ts';
import { RoutesEnum } from '@/shared/types/global/enums.ts';

type Props = WithChildren & {
  hasAccess: boolean;
};

export const ProtectedRoute: FC<Props> = ({ children, hasAccess }) => {
  if (!hasAccess) {
    return <Navigate replace to={RoutesEnum.HOME} />;
  }

  return children;
};
