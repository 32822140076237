import { FC } from 'react';

import { classnames } from '@/shared/utils/classnames.ts';

import { WithClassName } from '@/shared/types/global/common.types.ts';

type Props = WithClassName & {
  indicatorColor?: string;
};

export const IndicatorLoader: FC<Props> = ({ className, indicatorColor }) => {
  return (
    <span
      className={classnames(
        'counterAnimation relative flex size-1.5 items-center justify-center',
        className,
      )}
    >
      <span
        className={classnames(
          'absolute inline-flex h-full w-full animate-[ping_3s__ease-in-out_infinite] rounded-full opacity-75',
          indicatorColor ?? 'bg-white',
        )}
      />
      <span
        className={classnames(
          'relative inline-flex h-1.5 w-1.5 rounded-full',
          indicatorColor ?? 'bg-white',
        )}
      />
    </span>
  );
};
